@import "./src/styles/index.scss";

.c-share-music {
  margin-top: 20px;
  @include padding(0 24px);
}

.action-row {
  @include flex(space-between);
}

.start-time {
  @include flex();

  &__field {
    width: 45px;
    // margin-left: 15px;
    position: relative;

    &::after {
      content: '';
      background-color: $c-assist4;
      @include position(tl, 100%, 0);
      width: 45px;
      height: 1px;
    }

    &__text {
      width: 45px;
      border: 0 transparent;
      outline: 0 transparent;
      text-align: center;
      padding: 0;
      @include font-style($c-black, 14, normal, 0.7px, 26px);
    }
  }
}

.btn {
  &--email {}

  &--copy-link {}

  &__text {
    margin-left: 12px;
  }

  &__icon {}

  &+& {
    margin-left: 10px;
  }
}

.music-link {
  margin-top: 30px;
  margin-bottom: 20px;
  @include flex();
  @include padding(0 24px);
  height: 40px;
  border-radius: 20px;
  background-color: $c-assist1;

  &__input {
    width: 100%;
    @include font-style($c-assist6, 14, normal, 0.7px, 18px);
    border: 0px transparent;
    outline: 0px transparent;
    background-color: transparent;
  }
}

.err-msg {
  color: $c-error;
}

.pc-show {
  display: none;
}

.mobile-wrap {
  @include flex();
}

@media screen and (min-width: $tablet) {
  .c-share-music {
    margin-top: 45px;
  }

  .pc-show {
    display: block;
  }

  .mobile-show {
    display: none;
  }

  .pc-btn-wrap {
    @include flex();
  }
}
