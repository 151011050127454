@import "./src/styles/index.scss";

.c-add-note {
  @include padding(0 24px);

  &__remaining {
    @include flex(flex-end);
    @include font-style($c-assist3, 12, 600, 0.6px, 18px);
  }

  &__note {
    margin-top: 6px;
  }
}

.submit {
  margin-top: 26px;
  @include flex(center);
}

.cancel {
  margin-top: 16px;
  @include flex(center);

  &__btn {
    cursor: pointer;
    @include font-style($c-assist3, 14px, 600, 0.7px, 18px);
    border: 0px transparent;
    outline: 0px transparent;
    background-color: transparent;
  }
}

.note-list {
  background-color: $c-white3;
  height: 25vh;
  overflow: auto;

  &__item {
    @include padding(23px 20px);
    border-bottom: 1px solid $c-assist7;
  }
}

.item-row {
  @include flex(space-between);

  &__basic {
    &__title {
      @include font-style($c-black, 14, normal, 0.8px, 26px);
    }

    &__time {
      @include font-style($c-assist4, 12, normal, 0.6px, 18px);
    }
  }

  &__action {
    @include flex();

    &__edit {
      @include padding(0 10px);
      @include flex(center);
      display: inline-block;
      background-color: $c-assist15;
      height: 26px;
      border-radius: 13px;
      cursor: pointer;

      &__text {
        @include font-style($c-white, 14, normal, 0.7px, 26px);
      }
    }

    &__delete {
      margin-left: 20px;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: $sm-pc) {

  .note-list {
    &__item {
      @include padding(23px 0 23px 20px);
    }
  }

  .item-row {
    @include padding(0 80px 0 0);
  }
}
