@import "./src/styles/index.scss";

.c-track-info {
  &--padding {
    padding: 20px;
  }

  &__content {
    width: 100%;
    @include flex(flex-start, flex-start, column);
  }
}

.info-desc {
  @include font-style($c-black, 14, 400, 0.7px, 20px);
  @include padding(0 0 24px 0);
  margin-bottom: 24px;
  border-bottom: 1px solid $c-assist7;
  width: 100%;
}

.info-detail {
  width: 100%;
  @include flex(flex-start, flex-start);
}

.data-list {
  flex: 1;

  &__item {
    flex: 1;
    @include padding(0 5px);

    &+& {
      margin-top: 24px;
    }

    &__title {
      @include font-style($c-assist3, 14, bold, 0.7px, 26px);
      margin-bottom: 8px;
    }

    &__text {
      @include font-style($c-black, 14, 600, 0.7px, 26px);

    }
  }
}

.copy {
  flex: none;
  width: 18px;

  &__img {
    width: 100%;
    cursor: pointer;
  }
}

@media screen and (min-width: $tablet) {
  .c-track-info {
    @include padding(0 20px);

    &__content {
      flex-direction: row;
    }
  }

  .info-desc {
    display: none;
  }

  .data-list {
    width: 100%;
    @include flex(flex-start, flex-start);

    &__item {
      &+& {
        margin-top: 0px;
      }
    }
  }
}
