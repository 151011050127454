@import "./src/styles/index.scss";

.playlist {
  height: 300px;
  overflow: auto;
}

.playlist-list {
  &__item {
    @include padding(0 24px);
    @include flex();
    height: 55px;
    cursor: pointer;

    &:hover {
      background-color: $c-assist1;

      .item-box__icon {
        display: inline-block;
      }
    }
  }
}

.item-box {
  width: 100%;
  @include flex();

  &__title {
    flex: 1;
    @include font-style($c-assist6, 14, normal, 0.8px, 22px);
  }

  &__icon {
    display: none;
    flex: none;
    width: auto;
    margin-left: 10px;
  }
}

.create-field {
  @include padding(0 24px);
  margin: 0 24px;
  // border-top: 1px solid $c-assist7;

  &__box {
    margin-top: 20px;
    @include flex();
    @include padding(0px 24px);
    height: 40px;
    border-radius: 20px;
    background-color: $c-assist1;

    &__input {
      flex: 1;
      border: 0 transparent;
      outline: 0 transparent;
      background-color: transparent;
    }
  }
}

.add-btn {
  flex: none;
  width: auto;
  @include flex();
  border: 0 transparent;
  outline: 0 transparent;
  cursor: pointer;
  background-color: transparent;

  &__icon {
    width: 14px;
  }

  &__text {
    margin-left: 10px;
    @include font-style($c-main, 14, normal);
  }
}

.mobile-btn-wrap {
  margin-top: 15px;
  @include flex(center);
}

.show-tablet {
  display: none
}

.mobile-add-btn-text {
  margin-left: 10px;
}

@media screen and (min-width: $portrait) {
  .mobile-btn-wrap {
    display: none;

  }

  .show-tablet {
    display: block;
  }
}
