@import "./src/styles/index.scss";

.email {
  margin: 36px 45px 0;
  @include padding(0 25px);
  @include flex();
  border: 1px solid #a0a0a0;
  border-radius: 25px;
  height: 50px;

  &__input {
    @include font-style($c-assist3, 14, normal, 0.8px, 22px);
    border: 0 transparent;
    outline: 0 transparent;
    background-color: transparent;
    width: 100%;

    &::placeholder {
      @include font-style($c-assist3, 14, normal, 0.8px, 22px);
    }
  }
}

.btn-wrap {
  margin-top: 50px;
  margin-bottom: 20px;
  @include flex(center);
}

.error-message {
  margin: 5px 45px 0;
  @include font-style($c-main, 14, normal, 0.8px, 22px);
}
