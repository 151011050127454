@import "./src/styles/index.scss";

.menu-explore-list {
  @include flex();
  flex-wrap: wrap;
  margin: 0 -3.5px;

  &__item {
    flex: none;
    width: 50%;
    @include padding(0 3.5px);
    margin-top: 7px;
    cursor: pointer;

    &:first-child,
    &:nth-child(2) {
      margin-top: 0;
    }
  }
}

.item-box {
  // @include padding(0 3.5px);
  @include flex(center);
  width: 100%;
  height: 60px;
  background-size: cover;
  background-position: center;

  &__label {
    @include font-style($c-white, 18, 700, 0, 24px);
  }
}
