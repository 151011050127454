@import "./src/styles/index.scss";

.ly-menu-search-pop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: $c-black;
  z-index: $zi-search-pop;

  &__close {
    @include position(tr, 15px, 0px);
    cursor: pointer;
  }

  &__content {
    @include max-width(900);
    margin-top: 90px;
  }
}

.tags {
  margin-top: 50px;
  &__title {
    &__text {
      @include font-style($c-white, 20, normal, 1px, 26px);
    }

    &__count {
      margin-left: 3px;
      @include font-style($c-assist4, 12, normal, 0.6px, 16px);
    }
  }

  &__wrap {
    margin-top: 17px;
  }
}

.tabs {
  margin-top: 50px;
}

.search-result {
  margin-top: 27px;
}

.search-box-list {
  width: 100%;
  @include flex(flex-start, flex-start, column);
  margin: 0 -10px;

  &__item {
    flex: none;
    width: 100%;
    margin-top: 24px;

    & + & {
      margin-top: 20px;
    }

    &__box {
      @include padding(0 10px);
    }
  }
}

.btn-wrap {
  margin-top: 45px;
  margin-bottom: 45px;
  @include flex(center);
}

.show-tablet {
  display: none;
}

@media screen and (min-width: $tablet) {
  .show-mobile {
    display: none;
  }

  .show-tablet {
    display: block;
  }
  .ly-menu-search-pop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $c-black;
    z-index: $zi-search-pop;

    &__close {
      @include position(tr, 15px, 90px);
      cursor: pointer;
    }

    &__content {
      @include max-width(900);
      margin-top: 90px;
    }
  }

  .tags {
    margin-top: 50px;
    &__title {
      &__text {
        @include font-style($c-white, 20, normal, 1px, 26px);
      }

      &__count {
        margin-left: 3px;
        @include font-style($c-assist4, 12, normal, 0.6px, 16px);
      }
    }

    &__wrap {
      margin-top: 17px;
    }
  }

  .tabs {
    margin-top: 50px;
  }

  .search-result {
    margin-top: 27px;
  }

  .search-box-list {
    width: 100%;
    @include flex(flex-start, flex-start, row);
    flex-wrap: wrap;
    margin: 0 -10px;

    &__item {
      flex: none;
      width: 25%;
      margin-top: 24px;

      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: 0px;
      }

      &__box {
        @include padding(0 10px);
      }
    }
  }

  .btn-wrap {
    margin-top: 45px;
    @include flex(center);
  }
}
