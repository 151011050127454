@import "./src/styles/index.scss";

.c-search-input {
  width: 100%;
  @include padding(0 0 7px 0);
  border-bottom: 1px solid $c-assist11;

  &--is-search-pop {
    width: 100%;

    .c-search-input__content--search-pop-mobile {
      .c-mobile-search-function {
        width: 100%;
        @include flex(space-between);
      }
    }

    .c-search-input__content--default {
      margin-top: 18px;

      .search-keywrod-dropdown {
        display: none;
      }

      .select-wrap {
        display: none;
      }

      .full-match {
        display: none;
      }
    }

    .search-keyword {
      margin-left: 0;

      &__input {
        color: #fff;
      }
    }
  }

  &__content {
    width: 100%;
    @include flex();
  }

  &__content.show-tablet {
    display: none;
  }
}

.c-mobile-search-function {
  width: 100px;
  @include flex(space-between);
}

.c-mobile-search-input {
  margin-top: 12px;
  width: 100%;
  @include flex(space-between);

  .search-keyword {
    margin-left: 0;
  }
}

.select-wrap {
  @include flex();
  flex: none;
  width: 100px;
  border-right: 1px solid rgba($c-assist3, 0.5);

  &__text {
    width: 74px;
    @include font-style($c-assist6, 18, normal, 0.9px, 24px);
  }
}

.dropdown-icon {
  width: 25px;
  height: 25px;
  position: relative;
  cursor: pointer;

  &__left {
    width: 7px;
    height: 2px;
    background-color: $c-main;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 3px), -50%) rotate(-30deg);
    // left: 3px;
    transition: 0.3s;
  }

  &__right {
    width: 7px;
    height: 2px;
    background-color: $c-main;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 3px), -50%) rotate(30deg);
    // left: -3px;
    transition: 0.3s;
  }

  &--active {
    .dropdown-icon {
      &__left {
        transform: translate(calc(-50% + 3px), -50%) rotate(30deg);
      }

      &__right {
        transform: translate(calc(-50% - 3px), -50%) rotate(-30deg);
      }
    }
  }
}

.search-keyword {
  flex: 1;
  margin-left: 22px;

  &__input {
    width: 100%;
    border: 0px transparent;
    outline: 0px transparent;
    @include font-style($c-black, 18, normal, 0.9px, 24px);
    background-color: transparent;

    &::placeholder {
      @include font-style($c-assist3, 18, normal, 0.9px, 24px);
    }
  }
}

.full-match {
  ::v-deep .n-checkbox .n-checkbox__label {
    color: $c-white;
  }

  &--active {
    ::v-deep .n-checkbox .n-checkbox-box {
      background-color: $c-main;
    }
  }
}

.search-icon {
  flex: none;
  width: auto;
  margin-left: 22px;
  cursor: pointer;
}

@media screen and (min-width: $tablet) {
  .show-mobile {
    display: none;
  }

  .show-tablet {
    @include flex();
  }

  .c-search-input {
    width: 600px;
    @include padding(0 0 16px 0);
    margin-top: 20px;
    border-bottom: 1px solid $c-assist11;

    &--is-search-pop {
      width: 100%;

      .select-wrap {
        &__text {
          color: $c-white;
        }
      }
    }

    &__content {
      width: 100%;
      @include flex();
    }

    &__content.show-mobile {
      display: none;
    }

    &__content.show-tablet {
      @include flex();
    }
  }

  .select-wrap {
    @include flex(space-between);
    flex: none;
    width: 160px;

    border-right: 1px solid rgba($c-assist3, 0.5);

    &__text {
      @include font-style($c-assist6, 18, normal, 0.9px, 24px);
    }
  }

  .search-keyword {
    flex: 1;
    margin-left: 22px;

    &__input {
      width: 100%;
      border: 0px transparent;
      outline: 0px transparent;
      @include font-style($c-black, 18, normal, 0.9px, 24px);
      background-color: transparent;

      &::placeholder {
        @include font-style($c-assist3, 18, normal, 0.9px, 24px);
      }
    }
  }

  .full-match {
    ::v-deep .n-checkbox .n-checkbox__label {
      color: $c-white;
    }

    &--active {
      ::v-deep .n-checkbox .n-checkbox-box {
        background-color: $c-main;
      }
    }
  }

  .search-icon {
    flex: none;
    width: auto;
    margin-left: 22px;
    cursor: pointer;
  }
}
