@import "./src/styles/index.scss";

.c-version-info {}

.version-title {
  &__main {
    @include font-style($c-assist6, 14, bold, 0.7px, 26px);
  }

  &__sub {
    margin-left: 4px;
    @include font-style($c-assist4, 14, normal, 0.7px, 26px);
  }
}

.version-id {
  @include font-style($c-assist4, 14, normal, 0.7px, 26px);
}
