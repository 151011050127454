@import "./src/styles/index.scss";

.c-music-volume {
  @include flex();
  color: $c-white;
}

.track-volume {
  @include flex();

  &__track {
  }

  &__volume {
    margin-left: 28px;
    @include flex();
  }
}

.version-queue {
  &__version {
    @include font-style($c-assist3, 14);
    cursor: pointer;
  }

  &__queue {
    @include font-style($c-assist3, 14);
    cursor: pointer;
    margin-left: 10px;
  }
}

.volume {
  width: 18px;

  &__icon {
    width: 100%;
    vertical-align: bottom;
    cursor: pointer;
  }
}

.progress-wrap {
  margin-left: 16px;
  @include flex(center);
  height: 18px;
  cursor: pointer;
  width: 120px;
  position: relative;
}

.progress-static {
  background-color: $c-text1;
  height: 1px;
  width: 120px;
  @include position(center);
}

.progress-line {
  background-color: $c-main;
  height: 2px;
  width: 120px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  &__dot {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    @include circle(1px);
    background-color: $c-main;
    transition: 0.3s;
  }
}

.progress-wrap {
  &:hover {
    .progress-line__dot {
      right: -3px;
      @include circle(6px);
    }
  }
}

.c-music-volume--simple {
  .track-volume {
    @include flex();

    &__track {
      margin-left: 20px;
      order: 2;
    }

    &__volume {
      margin-left: 0px;
      order: 1;
    }
  }

  .version-queue {
    &__version {
      @include font-style($c-assist4, 14);
    }

    &__queue {
      @include font-style($c-assist4, 14);
      margin-left: 10px;
    }
  }
}
