@import "./src/styles/index.scss";

@import '~@/styles/utils/_transition.scss';

.freeze-body {
  overflow: hidden;
}

.re-dialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zi-dialog;
  width: 100%;
  height: 100%;
  background-color: rgba(#333, 0.5);

  &__box {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 320px;
    transform: translate(-50%, -50%);
    background-color: $c-white;
    border-radius: 20px;

    &__body {
      /* @include padding(10px); */
      @include padding(50px 75px);
    }
  }
}

.btn-wrap {
  margin-top: 30px;
  @include flex(center);
}

@keyframes slideTopIn {
  from {
    transform: translate(-50%, calc(-50% - 30px));
  }

  to {
    transform: translate(-50%, -50%);
  }
}

@keyframes slideTopOut {
  from {
    transform: translate(-50%, -50%);
  }

  to {
    transform: translate(-50%, calc(-50% - 30px));
  }
}
