@import "./src/styles/index.scss";

// fixme: 太多了吧，有空再整理
.n-checkbox .n-checkbox-box {
  background-color: #BA2424;
  border-color: #BA2424;
}

.n-checkbox .n-checkbox-box {
  background-color: #BA2424;
  border-color: #BA2424;
}

.n-checkbox .n-checkbox-box .n-checkbox-box__border {
  border-color: #BA2424;

  &:hover {
    border-color: #BA2424;
  }
}

.n-checkbox.n-checkbox--checked .n-checkbox-box {
  background-color: #BA2424;
  border-color: #BA2424;
}

.n-checkbox.n-checkbox--checked .n-checkbox-box {
  background-color: #BA2424;
  border-color: #BA2424;
}

.n-checkbox.n-checkbox--checked .n-checkbox-box .n-checkbox-box__border {
  border-color: #BA2424;

  &:hover {
    border-color: #BA2424;
  }
}

.n-checkbox:focus:not(:active) .n-checkbox-box .n-checkbox-box__border {
  box-shadow: 0 0 0 2px transparent;
  border-color: #BA2424;
}

.n-checkbox.n-checkbox--checked:focus:not(:active) .n-checkbox-box .n-checkbox-box__border {
  box-shadow: 0 0 0 0px transparent;
  border-color: #BA2424;
}

.n-checkbox .n-checkbox__label {

  &:hover {
    color: #BA2424;
  }
}
