@import "./src/styles/index.scss";

.c-label-item {
  height: 25px;
  @include padding(0 5px);
  @include inline-flex(center);
  border: 1px solid $c-main;
  margin-right: 10px;
  margin-bottom: 10px;

  &--is-search {
    height: 34px;
    @include padding(0 10px);

    .c-label-item__text {
      @include font-style($c-assist6, 14, 600, normal, 18px);
    }
  }

  &--is-search-pop {
    height: 34px;
    @include padding(0 10px);
    background-color: $c-main;

    .c-label-item__text {
      @include font-style($c-white, 14, 600, normal, 18px);
    }
  }

  &--allow-click {
    cursor: pointer;

  }

  &__text {
    @include font-style($c-main, 12, 600, normal, 18px);
  }
}
