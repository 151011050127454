@import "./src/styles/index.scss";

@import '~@/styles/share/pullFromShelf.scss';
.c-cover-name-code {
  @include flex();

  &--off-shelf {
    .c-cover-name-code__img {
      position: relative;

      &__mask {
        display: block;

        &::after {
          content: '';
          @include position(center);
          width: 100%;
          height: 100%;
          display: inline-block;
          background-color: rgba($c-black, 0.5);

        }
      }

      &__icon {
        display: inline-block;
        width: 20px;
        @include position(center);
      }
    }
  }

  &__img {

    &__mask {
      display: none;
    }

    &__icon {
      display: none;
    }

    &__cover {
      width: 36px;
      height: 36px;

    }
  }

  &__info {
    margin-left: 16px;
    @include flex(flex-start, flex-start, column);

    &__name {
      width: 100%;
      text-align: left;
      @include font-style($c-assist6, 14, bold, 0.8px, 18px);
      @include word-ellipsis(1);
    }

    &__code {
      text-align: left;
      @include font-style($c-assist4, 14, 400, 0.8px, 18px);
      @include word-ellipsis(1);
    }
  }
}
