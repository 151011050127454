@import "./src/styles/index.scss";

@import '~@/styles/share/functions.scss';

.btn-wrap {
  margin-top: 50px;
  @include flex(flex-end);

  &__btn {

    &+& {
      margin-left: 10px;
    }
  }
}

.select-wrap {
  margin-top: 20px;
}
