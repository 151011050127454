@import "./src/styles/index.scss";

.language-list {

  @include padding(10px 0);

  &--mobile-menu-content {
    @include flex();
    @include padding(0 20px);

    .language-list__item+.language-list__item {
      margin-left: 20px;

    }

    .language-list {
      &__item {
        &__label {
          @include font-size(14);

        }
      }
    }
  }

  &__item {
    @include flex(center);
    height: 40px;
    cursor: pointer;

    &:hover {
      .language-list__item__label {
        color: $c-main;
      }
    }

    &--active {
      .language-list__item__label {
        color: $c-main;
      }
    }

    &__label {
      @include font-style($c-assist6, 14, normal, 0.8px, 22px);
    }
  }
}
