@import "./src/styles/index.scss";

.c-time-length {
  @include font-style($c-assist4, 14, 400, 0.8px, 26px);
}

@media screen and (min-width: $sm-pc) {
  .c-time-length {
    color: $c-black;

    &--white {
      color: $c-white;
    }
  }
}
