
.pull-from-shelf {
  @include flex();
  margin-left: 20px;

  &--ml45 {
    margin-left: 45px;
  }

  &__icon {
    width: 20px;
  }

  &__text {
    @include font-style($c-assist3, 14, 400);
    margin-left: 5px;
  }
}
