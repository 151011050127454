@import "./src/styles/index.scss";

.item-content {
}

.title {
  @include flex(space-between);
  @include padding(0 20px);
  cursor: pointer;

  &__text {
    @include flex();
    @include font-style($c-black, 20, 700, 0, 27px);
    height: 42px;
  }

  &__icon {
    width: 7.5px;
  }
}

.extra-content {
  @include padding(0 20px 10px);
}

.divide {
  width: 100%;
  height: 1px;
  background-color: $c-assist7;
  margin: 24px 0;
}
