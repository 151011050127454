@import "./src/styles/index.scss";

.c-music-progress {
  width: 100%;
  background-size: cover;
  background-position: center;

  &--margin-left {
    margin-left: 30px;
  }
}
.music-progress {
  @include flex(center, center);
  flex: 1;

  &--mobile-music-player {
    .music-progress__wrap {
      margin: 0;
    }

    .progress-wave__box__remaining {
      min-height: 20px;
      height: 3vh;
    }

    .progress-wave__box__pass {
      min-height: 20px;
      height: 3vh;
    }
  }

  &__current-time {
    @include font-style($c-white, 14);
    flex: none;

    &--preview {
      color: $c-assist4;
      padding-right: 22px;
    }
  }

  &__wrap {
    flex: 1;
    margin: 0 8px;
  }

  &__total-time {
    @include font-style($c-white, 14);
    flex: none;
    width: auto;
    text-align: right;

    &--preview {
      color: $c-assist4;
      padding-left: 22px;
    }
  }
}

.progress-wave {
  &__box {
    width: 100%;
    position: relative;
    left: -1;
    // background-color: $c-assist3;
    background-color: transparent;
    height: 56px;

    &__pass {
      // @include position(tl, 0, 0);
      @include position(tl, 0, 0);
      width: 100%;
      height: 56px;
      overflow: hidden;
      vertical-align: middle;
      background-repeat: no-repeat;
      background-color: rgba(0, 0, 0, 0.12);
      // -webkit-mask: url('') no-repeat;
      -webkit-mask-size: 100% 100%;
      background-size: 100% 100%;
      @include optimizeImage();

      &--preview {
        background-color: $c-assist7;
      }

      &--mobile-music-player {
        height: 20px;
      }
    }

    &__remaining {
      width: 100%;
      height: 56px;
      @include position(tl, 0, 0);
      background-color: $c-main;
      // -webkit-mask: url('') no-repeat;
      -webkit-mask-size: 100% 100%;
      background-size: 100% 100%;
      @include optimizeImage();
    }
  }
}

.progress-wrap {
  @include flex(center);
  height: 24px;
  cursor: pointer;
  position: relative;
}

.progress-static {
  background-color: $c-text1;
  height: 1px;
  width: 100%;
  background-color: #fff;
  @include position(center);
}

.progress-line {
  background-color: $c-main;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  &__dot {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    @include circle(1px);
    background-color: $c-main;
    transition: 0.3s;
  }
}

.progress-wrap {
  &:hover {
    .progress-line__dot {
      right: -3px;
      @include circle(6px);
    }
  }
}

.mobile-music-player-music-progress-time {
  @include flex(space-between);
  flex: 1;

  &__current-time {
    @include font-style($c-white, 12);
    flex: none;
  }

  &__total-time {
    @include font-style($c-white, 12);
    flex: none;
    width: auto;
    text-align: right;
  }
}
