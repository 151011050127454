@import "./src/styles/index.scss";

.content-row {
  width: 100%;
  height: 52px;
  @include flex();

  &--remain-row-height {
    height: 78px;
  }

  &__item {
    @include flex();
    @include padding(20px 0);

    &__text {
      @include font-style($c-white, 14, normal, 0.8px, 26px);
    }

    &__cell {
      width: 100%;

      &--padding-left {
        width: 100%;
      }
    }

    &__checkbox {
      width: 100%;
      @include flex(center);
    }
  }
}

@media screen and (min-width: $pc) {
  .content-row {
    height: 78px;
  }
}
