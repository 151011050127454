@import "./src/styles/index.scss";

.c-mobile-function {
  width: 100%;
  height: 100%;
  background-color: rgba($c-black, 0.2);
  position: fixed;
  top: 100%;
  left: 0;
  z-index: $zi-mobile-function;
  transition: 0.3s;
  animation: fadeOut 0.4s forwards;

  &--active {
    display: inline-block;
    animation: fadeIn 0.3s forwards;
  }
}

.c-mobile-album-function {
  width: 100%;
  height: auto;
  background-color: rgba($c-white, 0.8);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  transition: 0.3s;
  @include position(tl, 100%, 0);
  animation: contentSlideOut 0.3s  forwards;

  &--active {
    animation: contentSlideIn 0.3s forwards;
    background-color: rgba($c-white, 1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    top: 0%;
  }
  to {
    opacity: 1;
    top: 0%;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    top: 0%;
  }

  99% {
    top: 0%;
  }

  100% {
    opacity: 0;
    top: 100%;
  }
}

@keyframes contentSlideIn {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes contentSlideOut {
  from {
    transform: translateY(-100%);
    }
  to {
    transform: translateY(0%);
  }
}

.header {
  @include flex(space-between);
  border-bottom: 1px solid $c-assist7;
  @include padding(13px 20px);

  &__album-info {
  }

  &__close {
    width: 18px;

    &__img {
      width: 100%;
      cursor: pointer;
    }
  }
}

.function-list {
  @include padding(20px);

  &__item {
    & + & {
      margin-top: 20px;
    }
  }
}
