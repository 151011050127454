@import "./src/styles/index.scss";

.mi-select-option {
  @include padding(10px);
  @include font-style($c-assist, 14px);
  @include flex();
  width: 100%;
  color: $c-assist;
  cursor: pointer;
  min-height: 40px;
  height: auto;
  font-size: 0;

  &:not([data-label='true']),
  &[data-disabled-status='true'] {
    &:hover {
      color: $c-black;
      background-color: rgba($c-assist3, 0.15);
    }
  }

  &--active {
    color: $c-black;
  }

  &--label {
    @include font-style($c-assist4, 14, normal, 0.7px);
    position: relative;
    background-color: transparent;
    cursor: default;

    &::after {
      @include position(tl, calc(100% - 1px), 50%);
      transform: translateX(-50%);
      content: '';
      display: inline-block;
      width: calc(100% - 20px);
      height: 1px;
      background-color: #ccc;
    }
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &--second-layer {

    .mi-select-option__text {
      font-weight: bold;
      color: $c-black;
    }

  }

  &__text {
    width: calc(100% - 30px);
    @include font-style($c-assist4, 14, normal, 0.7px);
    // @include word-ellipsis();
    word-break: normal;
    white-space: normal;

    &--third-layer {
      margin-left: 14px;

    }
  }

  &__icon {
    flex: none;
    width: 30px;
    text-align: center;
  }
}
