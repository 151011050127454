// .function-icon {
//   @include inline-flex();
//   cursor: pointer;

//   &__img {
//   }

// }

.function-icon {
  @include flex();
  cursor: pointer;

  &__label {
    margin-left: 15px;
    @include font-style($c-assist6, 14, 500, 0.8px, 6px);
  }

  &.loading {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &__img {
    width: 22px;
    height: 22px;
    vertical-align: bottom;
    // cursor: pointer;
    background-size: 24px 24px;
    cursor: inherit;

    &--play {
      background-image: url("~@/assets/musicPlayer/playbar_play.svg");
    }

    &--add {
      background-image: url("~@/assets/musicPlayer/playbar_add.svg");
    }

    &--star {
      background-image: url("~@/assets/musicPlayer/playbar_star.svg");
    }

    &--star--white {
      background-image: url("~@/assets/musicPlayer/playbar_star_white.svg");
    }

    &--download {
      background-image: url("~@/assets/musicPlayer/playbar_download.svg");
    }

    &--note {
      background-image: url("~@/assets/musicPlayer/playbar_note.svg");
    }

    &--note--white {
      background-image: url("~@/assets/musicPlayer/playbar_note_white.svg");
    }

    &--note--has-note {
      background-image: url("~@/assets/musicPlayer/playbar_note_active.svg");
    }

    &--share {
      background-image: url("~@/assets/musicPlayer/playbar_share.svg");
    }

    &--share-playlists {
      background-image: url("~@/assets/musicPlayer/playbar_share.svg");
    }

    &--invite {
      background-image: url("~@/assets/musicPlayer/playbar_invite.svg");
    }

    &--remove {
      background-image: url("~@/assets/musicPlayer/playbar_delete.svg");
    }
    &--move {
      background-image: url("~@/assets/musicPlayer/playbar_move.svg");
    }

    &--menu-move {
      background-image: url("~@/assets/musicPlayer/playbar_menu_move.svg");
    }
  }
}

@media screen and (min-width: $tablet) {
  .function-icon {
    &:hover {
      .function-icon__img {
        &--play {
          background-image: url("~@/assets/musicPlayer/playbar_play_active.svg");
        }

        &--add {
          background-image: url("~@/assets/musicPlayer/playbar_add_active.svg");
        }

        &--star {
          background-image: url("~@/assets/musicPlayer/playbar_star_active.svg");
        }

        &--download {
          background-image: url("~@/assets/musicPlayer/playbar_download_active.svg");
        }

        &--note {
          background-image: url("~@/assets/musicPlayer/playbar_note_active.svg");
        }

        &--share {
          background-image: url("~@/assets/musicPlayer/playbar_share_active.svg");
        }

        &--share-playlists {
          background-image: url("~@/assets/musicPlayer/playbar_share_active.svg");
        }
        &--invite {
          background-image: url("~@/assets/musicPlayer/playbar_invite_active.svg");
        }
      }
    }
  }
}
