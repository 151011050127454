@import "./src/styles/index.scss";

@import '~@/styles/share/animate.scss';

.c-music-player {
  // display: none;
  position: relative;
  z-index: $zi-mobile-player;
}

.mobile-music-player {
  background-color: $c-black;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 55px;

  &__content {
    @include padding(0 30px 12px);
  }
}

.mobile {
  @include flex();

  &__album-cover {
    position: relative;
    top: -7px;

    &__img {
      width: 50px;
      height: 50px;
      vertical-align: bottom;
    }
  }

  &__main {
    width: 100%;
    @include flex();

    &__music-info {
      flex: 1;
      // sflex: abs($number: 0);
    }

    &__music-controll {
      flex: none;
      width: auto;
    }
  }
}

.standard-style {
  display: none;
}

@media screen and (min-width: $tablet) {
  .mobile-music-player {
    display: none;
  }

  .c-music-player {
    display: inline-block;
    position: fixed;
    z-index: $zi-player;

    &__type {}

    &__content {
      width: 100%;
      height: 100%;
      // position: relative;
      // z-index: 10;
    }
  }

  .music-wave {
    @include position(center);
    @include flex(center);
    width: 40px;
    height: 40px;

    &__bar {
      width: 6px;
      height: 40px;
      background-color: $c-white;
      transform-origin: bottom;

      &__wave1 {
        animation: wave1 0.45s infinite linear;
      }

      &__wave2 {
        animation: wave2 0.7s infinite linear;
      }

      &__wave3 {
        animation: wave3 0.9s infinite linear;
      }

      &__wave4 {
        animation: wave4 0.58s infinite linear;
      }

      &+& {
        margin-left: 5px;
      }
    }
  }

  .circle {
    width: 100%;
    height: 100%;
    position: relative;

    &__svg {
      @include position(center);
    }

    &__svg-static {
      stroke: $c-assist3;
      stroke-width: 1px;
      fill: transparent;
      fill-opacity: 0;
      @include position(center);
    }

    &__svg-dynamic {
      stroke: $c-white;
      stroke-width: 1px;
      fill: transparent;
      fill-opacity: 0;
      @include position(center);
    }
  }

  .dashboard {
    @include flex();
    width: 100%;

    &__album-cover {
      width: 110px;

      &__img {
        width: 100%;
        vertical-align: bottom;
        margin-top: -80px;
      }
    }

    &__main {
      flex: 1;
      width: 100%;
      @include flex(flex-end, flex-end, column);

      &__upper {
        width: 100%;
        @include flex(flex-start);

        &__music-info {
          flex: none;
          width: auto;
          display: flex;
          align-self: flex-end;
        }

        &__music-controll {
          margin-left: 26px;
          flex: 1;
        }

        &__music-function {
          flex: none;
          width: auto;
          margin-left: 26px;
        }
      }

      &__bottom {
        margin-top: 5px;
        margin-left: 10px;

        &__music-sound {}
      }
    }

    &__switch-icon {
      flex: none;
      width: auto;
      margin-left: 4px;
      display: flex;
      align-self: flex-start;
      cursor: pointer;

      &__img {
        width: 30px;
      }
    }
  }

  // 圓形播放器
  .c-music-player--circle {
    @include circle(100px);
    bottom: 80px;
    right: 80px;
  }

  .standard-style {
    display: block;
  }

  // 完整版的播放器
  .c-music-player--full {
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    .standard-style {
      background-color: rgba($c-black, 0.92);
      position: relative;
      @include padding(20px 20px 18px 36px);
      width: 100%;
      // height: auto;
      height: 120px;
    }
  }

  // 簡易版的播放器
  .c-music-player--simple {
    @include max-width(1700);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    .standard-style {
      background-color: $c-black;
      position: relative;
      @include padding(10px);
      height: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .dashboard {
      &__main {
        @include flex(flex-start, center, row);

        &__bottom {
          margin-top: 0px;
          margin-left: 93px;
        }
      }

      &__switch-icon {
        margin-left: 40px;
        align-self: center;
      }
    }
  }
}

@keyframes wave1 {
  0% {
    transform: scaleY(0.9);
  }

  50% {
    transform: scaleY(0.75);
  }

  100% {
    transform: scaleY(0.9);
  }
}

@keyframes wave2 {
  0% {
    transform: scaleY(0.9);
  }

  50% {
    transform: scaleY(0.5);
  }

  100% {
    transform: scaleY(0.9);
  }
}

@keyframes wave3 {
  0% {
    transform: scaleY(1.1);
  }

  50% {
    transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(1.1);
  }
}

@keyframes wave4 {
  0% {
    transform: scaleY(1.05);
  }

  50% {
    transform: scaleY(0.6);
  }

  100% {
    transform: scaleY(1.05);
  }
}
