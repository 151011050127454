// global

// form label
.n-form-item .n-form-item-label {
  @include font-style($c-assist3, 14, normal, 0.32px, 24px);
}

// 輸入框
.n-input .n-input__input-el {
  height: 45px;
}

.n-input.n-input--disabled .n-input__input-el,
.n-input.n-input--disabled .n-input__textarea-el {
  @include font-style(rgba($c-black, 0.4), 16, normal, 0.32px, 24px);
  color: rgba($c-black, 0.4);
  opacity: 1;
  -webkit-text-fill-color: rgba($c-black, 0.4);
}

// 錯誤訊息
.n-form-item .n-form-item-feedback-wrapper {
  min-height: 24px;
}

// checkbox
.n-checkbox {
  @include flex();
}

.n-checkbox .n-checkbox-box {
  background-color: transparent;
  width: 20px;
  height: 20px;
}

.n-checkbox .n-checkbox__label {
  @include font-style(rgba($c-assist6, 0.7), 16, normal, 0.8px, 20px);
  transition: 0.3s;

  &:hover {
    color: $c-assist6;
  }
}

// select

.n-base-selection {
  height: 45px;
  display: flex;
  align-items: center;

  .n-base-selection-tags {
    height: 100%;
  }
}

// model

.n-dialog {
  // min-width: 500px;
  width: 100%;
  // max-width: 60%;
  padding: 20px 0px;
  border-radius: 14px;

  .n-dialog__content {
    margin-top: 0;
  }
}

@media screen and (min-width: $tablet) {
  .n-dialog.n-modal {
    min-width: 500px;
    width: 100%;
    max-width: 500px;
  }
}
