@import "./src/styles/index.scss";

.ly-mobile-menu-content {
  position: fixed;
  top: 60px;
  left: 0;
  height: calc(100% - 60px);
  width: 100%;

  z-index: $zi-mobile-menu-content;
  background-color: $c-white;

  &__content {
    height: 100%;
    overflow: auto;
  }
}

.member-info {
  @include flex();
  background-color: $c-white;
  @include padding(20px 20px 0);
  // border-bottom 1px solid #

  &--not-login {
    display: block;
  }

  &__sign-in {
    @include flex();

    &__user-icon {
      width: 40px;
      margin-right: 10px;
    }
  }

  &__no-account {
    @include font-style($c-assist3, 14, bold, 0.28px, 24px);

    &__underline {
      display: inline-block;
      color: $c-assist3;
      text-decoration: underline;
      margin-top: 20px;
      cursor: pointer;
    }
  }

}

.mobile-menu {
  margin-bottom: 30px;
}

.declare {
  @include padding(0 20px);
  margin-bottom: 100px;
}

.declare-list {
  @include flex();

  &__item {
    position: relative;

    & + & {
      padding-left: 21px;

      &::before {
        content: '';
        height: 14px;
        width: 1px;
        background-color: $c-assist16;
        @include position(tl, 50%, 10px);
        transform: translateY(-50%);
        display: inline-block;
      }
    }

    &__text {
      @include font-style($c-assist16, 14, 400, 0, 0);
      cursor: pointer;
    }
  }
}

.language {
  @include position(bl, 0px, 0);
  @include padding(0 20px);
  @include flex();
  height: 50px;
  background-color: $c-assist2;
  margin-top: 10px;
  width: 100%;

  &::before {
    content: '';
    width: 100%;
    height: 50px;
    background: linear-gradient(0deg, rgba($c-white, 1) 0%, transparent 100%);
    @include position(bl, 100%, 0);
  }
}

