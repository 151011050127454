@import "./src/styles/index.scss";

.c-search-box-item {
  @include flex(flex-start, space-between, row);
  width: 100%;
  cursor: pointer;

  &__cover {
    width: 70px;
    height: 70px;
  }

  &__info {
    margin-left: 16px;
    @include flex(flex-start, flex-start, column);

    &__name {
      @include font-style($c-white, 14, bold, 0.8px, 26px);
      @include word-ellipsis(2)
    }

    &__id {
      @include font-style($c-assist2, 14, normal, 0.8px, 18px);
    }
  }
}
