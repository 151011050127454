// basic
$c-main: #BA2424;
$c-gradient1: #12C5FF;
$c-gradient2: #8431FF;
$c-gradient3: #F8559E;

$c-main-light: rgba($c-main, 0.4);

$c-assist: #696969;

// pure
$c-white: #fff;
$c-black: #000;

$c-white2: #F7F7F7;
$c-white3: #F9F9F9;
$c-black2: #242424;

$c-text1: #A2A2A2;
$c-text2: #CECDCD;
$c-text3: #535353;
$c-text4: #D3D3D3;

$c-assist1: #f4f4f4;
$c-assist2: #ECECEC;
$c-assist3: #A0A0A0;
$c-assist4: #808080;
$c-assist5: #EFEFEF;
$c-assist6: #484848;
$c-assist7: #E0DFDF;
$c-assist8: #313131;
$c-assist9: #727272;
$c-assist10: #D9D9D9;
$c-assist11: #A6A6A6;
$c-assist12: #191919;
$c-assist13: #4d4d4d;
$c-assist14: #5F5E5E;
$c-assist15: #c7c7c7;
$c-assist16: #B5B5B5;
$c-assist17: #707070;

$c-input-placeholder: #5F5E5E;
$c-home-search-bg: #FFFFFFD9;


$c-divide-red: #C30013;

// error
$c-error: #CD5C5C;