@import "./src/styles/index.scss";

.c-mobile-music-player {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zi-mobile-player;
  background-size: 150%;
  background-position: center;

  &__content {
    height: 100%;
    @include flex(flex-start, flex-start, column);
    backdrop-filter: blur(30px);
    background-color: rgba($c-black, 0.5);
  }
}

.player {
  flex: 1;
  height: 100%;
  width: 100%;
  margin: 0 auto;

  &__row {
    @include flex(center, center, column);

    &--top {
      flex: none;
      height: 10%;
      width: 100%;
      @include flex(space-between);
      @include padding(0 30px);
    }

    &--cover {
      // flex: none;
      height: calc(55% - 60px);

      flex: 1;
      @include padding(0 40px);
      // margin-top: 20px;
    }

    &--album-info {
      flex: none;
      height: 15%;
      text-align: center;
      @include padding(0 40px);
    }

    &--music-progress {
      flex: none;
      height: 10%;
      width: 100%;
      @include padding(0 40px);
    }

    &--music-control {
      flex: none;
      height: 10%;
      width: 100%;
      @include padding(0 40px);
    }

    &--music-info {
      flex: none;
      width: 100%;
      height: 60px;
      // 高於跑馬燈
      position: relative;
      z-index: 10;
    }
  }
}

.top {
  &__down-arrow {
    width: 30px;
    height: 30px;
    @include flex(center);

    &__icon {}
  }

  &__to-album {
    @include flex();
    cursor: pointer;

    &__text {
      @include font-style(rgba($c-white, 0.6), 14, 400, 0, 18px);
    }

    &__arrow-icon {
      margin-left: 4px;
    }
  }

  &__functions {
    &__icon {
      width: 16px;
    }
  }
}

.cover-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.album-info {
  &__song-name {
    width: 100%;
    @include font-style($c-white, 24, 700, 0, 45px);
    white-space: nowrap;
    overflow: auto;

    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &__id {
    margin-top: 8px;
    @include font-style($c-white, 14, 700, 0, 22px);
  }
}

.music-control {
  width: 100%;

  &__wrap {
    width: 100%;
    @include flex(space-between);

    &__icon {}
  }
}

.circle-bg {
  @include circle(50px);
  @include flex(center, center);
  background-color: rgba($c-white, 0.2);

  &__cell {
    position: relative;
    top: 1px;
    left: 1px;
  }
}

@media screen and (min-width: $portrait) {
  .player {
    max-width: 67vw;
  }
}

@media screen and (min-width: $tablet) {
  .c-mobile-music-player {
    display: none;
  }
}
