@import "./src/styles/index.scss";

.c-loading {
  @include flex(center);
  margin: 10px;

  svg path,
  svg rect {
    fill: $c-main;
  }

  &--white {
    svg path,
    svg rect {
      fill: $c-white;
    }
  }

  &--grey {
    svg path,
    svg rect {
      fill: $c-text1;
    }
  }
}
.loader {
  text-align: center;
  display: inline-block;
}
