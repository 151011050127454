@import "./src/styles/index.scss";

.c-my-music-list-header {
  // background-color: $c-white;
  // box-shadow: 0px 3px 10px rgba($c-black, 0.05);

  &__content {
    width: 100%;
    border-bottom: 1px solid $c-assist8;
    // @include max-width(1330);
    @include padding(0 0 20px 0);
  }
}
.header-list {
  width: 100%;
  @include flex();
  // @include padding(16px 0);

  &__item {
    @include font-style($c-assist4, 14, medium, 0.7px, 20px);
  }
}

.center {
  @include flex(center);
}
