@import "./src/styles/index.scss";

.c-title-with-function {
  width: 100%;
  @include flex();

  &__title {
    @include flex();
    flex: none;
    width: 500px;

    &__image {
      cursor: pointer;
      display: block;
      margin-right: 10px;
      // padding-bottom: 20%;
      width: 60px;
      height: 60px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &__text {
      @include font-style($c-white, 14, bold, 0.8px, 26px);
      @include word-ellipsis(2)
    }

    &__sub-text {
      @include font-style($c-white, 14, bold, 0.8px, 26px);
      @include word-ellipsis()
    }

    &__extra {
      @include font-style($c-white, 14, normal, 0.8px, 26px);
    }
  }

  &__function {
    flex: 1;
  }
}
