@import "./src/styles/index.scss";

.c-version {
  width: 100%;
  background-color: $c-assist12;
  @include position(tl, -60vh, 0);
  height: 60vh;

  &__content {
    @include max-width(1300);
    @include padding(60px 0 100px);
    @include flex(flex-start, flex-start, column);
    width: 100%;
    height: 100%;
  }
}

.close {
  @include position(tr, 0, 0);
  width: 55px;
  height: 55px;
  @include flex(center);
}

.header-wrap {
  width: 100%;
  flex: none;
  height: auto;

  &__content {
    @include flex(space-between);

    &__left {
      @include flex();
    }

    &__right {
      @include flex();
    }
  }
}

.title {
  @include font-style($c-white, 24, bold, 1.2px, 30px);
}

.count {
  @include font-style($c-assist4, 14, bold, 0.7px, 26px);
  margin-left: 18px;
}

.my-music-list-wrap {
  width: 100%;
  height: 100%;
  flex: 1;
  margin-top: 48px;
}
