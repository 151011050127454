@import "./src/styles/index.scss";

.c-add-playlists-btn {
  margin-left: 12px;

  & + & {
    margin-left: 12px;
  }
}
.add-icon {
  width: 15px;
}

.add-text {
  margin-left: 9px;
}

@media screen and (min-width: $sm-pc) {
  .c-add-playlists-btn {
    margin-left: 0px;
  }
}
