@import "./src/styles/index.scss";

.c-my-music-list-content {
  &__content {

  }
}

.content-list {
  &__item {
    box-shadow: 0px 0px 10px rgba($c-black, 0.05);
    border-radius: 5px;

    & + & {
      border-top: 1px solid $c-assist8;
    }
  }
}
