@import "./src/styles/index.scss";

.c-my-music-list {
  width: 100%;
  height: 100%;
  @include flex(flex-start, flex-start, column);

  &__header {
    width: 100%;
    flex: none;
    height: auto;
  }

  &__content {
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: auto;
  }
}
