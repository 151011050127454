@import "./src/styles/index.scss";

.c-download-btn {
  margin-left: 12px;
}
.download-icon {
  width: 25px;
}

.download-text {
  margin-left: 5px;
}
