@import "./src/styles/index.scss";

.sign-in {
  flex: none;
  width: 50%;
  @include flex(flex-start);

  &__content {
    @include padding(0 10px);
    @include inline-flex(space-between);
    background-color: $c-main;
    width: 100px;
    height: 32px;
    border-radius: 16px;
    cursor: pointer;

    &__text {
      @include font-style($c-white, 12, normal, 0px, 16px);
    }

    &__icon {
      width: 13px;
      margin-left: 5px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .sign-in {
    @include flex(center, center);

    &__content {
      @include padding(36px 0 30px 0);
      @include flex();
      background-color: transparent;
      width: auto;

      &__text {
        @include font-style($c-assist4, 14, normal, 0.8px, 18px);
      }

      &__icon {
        width: 20px;
        margin-left: 12px;
      }
    }
  }
}
