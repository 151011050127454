@import "./src/styles/index.scss";

.c-id {
  @include font-style($c-assist4, 14, 400, 0.7px, 26px);
  @include word-ellipsis(2, 'true');

  &--pr {
    @include padding(0 30px 0 0);

  }

  &--white {
    color: $c-white;
  }

}

@media screen and (min-width: $tablet) {
  .c-id {
    color: $c-black;
    // @include padding(0 10px 0);

    &--white {
      color: $c-white;
    }
  }
}
