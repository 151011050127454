@import "./src/styles/index.scss";

.c-red-circle-button {
  cursor: pointer;
  height: 52px;
  @include flex();
}

.red-circle-wrap {
  position: absolute;
  left: -28px;
  top: 50%;
  transform: translateY(-50%);
}
.red-circle {
  @include circle(52px);
  @include position(center);
  transition: 0.3s;
  border: 3px solid $c-main;
  pointer-events: none;
}

.label-text-wrap {
  display: inline-block;
  // padding: 8px 10px 12px 0px;
  margin-bottom: 5px;
  margin-left: -4px;
  position: relative;
  z-index: 10;
  border-radius: 4px;
  white-space: nowrap;
}

.label-text {
  @include font-size();
  letter-spacing: 0.8px;
  font-weight: bold;
}

svg {
  width: 52px;
  .cls-1,
  .cls-2 {
    fill: none;
    stroke: #c30013;
    stroke-miterlimit: 10;
    stroke-width: 3px;
  }
  .cls-1 {
    transition-delay: 0.17s;
  }
  .cls-2 {
    opacity: 0;
    transition-property: d, stroke-width, opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }
}

.c-red-circle-button {
  position: relative;

  &:hover {
    .red-circle {
      width: 0px;
      height: 0px;
      border: 6px solid $c-main;
    }
    svg {
      .cls-1 {
        transition-delay: 0s;
        opacity: 0;
        d: path('M70.55,37.5a33.05,33.05,0,1,1-33-33A33.05,33.05,0,0,1,70.55,37.5');
      }
      .cls-2 {
        d: path('M41.5,37.5a4,4,0,1,1-4-4,4,4,0,0,1,4,4');
        transition-property: d, stroke-width;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        stroke-width: 8px;
        opacity: 1;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .c-red-circle-button {
    height: 72px;
  }

  .red-circle {
    @include circle(72px);
  }

  .label-text-wrap {
    margin-bottom: 0px;
  }

  svg {
    width: 72px;
  }
}
