@import "./src/styles/index.scss";

.c-music-info {
  color: $c-white;
  @include flex(flex-start, flex-start);
}

.album-text {
  margin-left: 20px;
  position: relative;
  top: 0px;

  &__song-name {
    @include font-style($c-white, 12, 500, 0, 15px);
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
  }

  &__title {
    margin-top: 4px;
    @include font-style(rgba($c-white, 0.8), 12, 500, 0.6px, 15px);
  }
}

.c-music-info--simple {
  .album-text {
    &__song-name {
      width: 202px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .c-music-info {
    color: $c-white;
    @include flex(flex-end, flex-end);
  }

  .album-text {
    &__song-name {
      @include font-style($c-white, 14, 400);

      width: 154px;
    }

    &__title {
      @include font-style(rgba($c-white, 0.8), 16, 400);
    }
  }
}
