@import "./src/styles/index.scss";

@import '~@/styles/share/pullFromShelf.scss';

.c-music-function {
  color: $c-white;
  position: relative;
  @include flex();

  // &__content {
  //   position: relative;
  //   @include flex();
  // }
}

.function-list {
  width: 100%;
  max-width: 300px;
  @include flex();

  &--margin-left {
    margin-left: 45px;
  }

  &__item {
    &+& {
      margin-left: 12px;
    }

    &--play {
      margin-right: 24px;
    }
  }
}

.expand {
  vertical-align: bottom;
  cursor: pointer;
  transition: 0.3s;
  width: 45px;
  height: 45px;
  @include flex();

  &__img {
    transform: rotate(0deg);
    transition: 0.3s;

    &--active {
      transform: rotate(-180deg);
    }
  }
}

.show-empty-expand {
  width: 45px;
}
