#html {
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-size: 16px !important; // set for rem base
}

* {
  // outline: 1px solid rgba($c-main, 0.3);
  // scrollbar-color: #87ceeb #ff5621;
}

body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  // Noto Sans TC 中文字體

  font-family:
    "Noto Sans TC",
    "Noto Sans SC",
    sans-serif,
    "PingFang TC",
    Microsoft JhengHei,
    微軟正黑體,
    Arial,
    Helvetica,
    sans-serif;
}

// 阻擋下拉使瀏覽器重整
.prevent-scroll-reload {
  overscroll-behavior-y: contain;
}

#app {
  width: 100%;
  height: 100%;
}

.app-root,
.n-config-provider {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  // overflow: auto;
}

// // 下面有漸層
// .item-hover--bottom {
//   position: relative;
//   cursor: pointer;

//   &:hover {
//     box-shadow: 0px 0px 10px #0000001A;

//     &:after {
//       content: '';
//       height: 5px;
//       width: 100%;
//       display: inline-block;
//       border-bottom-left-radius: 5px;
//       border-bottom-right-radius: 5px;
//       @include position(tl, calc(100% - 5px), 0);
//       @extend %bg-gradient-90deg;
//     }
//   }
// }

// // 右邊有漸層
// .item-hover--right {
//   position: relative;
//   cursor: pointer;

//   &:hover {
//     box-shadow: 0px 0px 10px #0000001A;

//     &:after {
//       content: '';
//       height: 100%;
//       width: 5px;
//       display: inline-block;
//       border-top-right-radius: 5px;
//       border-bottom-right-radius: 5px;
//       @include position(tr,  0, 0);
//       @extend %bg-gradient;
//     }
//   }
// }

// // 左邊有漸層
// .item-hover--left {
//   position: relative;
//   cursor: pointer;

//   &:hover {
//     box-shadow: 0px 0px 10px #0000001A;

//     &:after {
//       content: '';
//       height: 100%;
//       width: 5px;
//       display: inline-block;
//       border-top-left-radius: 5px;
//       border-bottom-left-radius: 5px;
//       @include position(tl,  0, 0);
//       @extend %bg-gradient;
//     }
//   }
// }

.clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

@media screen and (min-width: $portrait) {
  // 下面有漸層
  .item-hover--bottom {
    position: relative;
    cursor: pointer;

    // &:after {
    //   content: '';
    //   height: 5px;
    //   width: 100%;
    //   display: inline-block;
    //   border-bottom-left-radius: 5px;
    //   border-bottom-right-radius: 5px;
    //   @include position(tl, calc(100% - 5px), 0);
    //   @include bg-gradient-90deg();
    // }

    &:hover {
      box-shadow: 0px 0px 10px #0000001a;

      &:after {
        content: "";
        height: 5px;
        width: 100%;
        display: inline-block;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        @include position(tl, calc(100% - 5px), 0);
        @include bg-gradient-90deg();
      }
    }
  }

  // 右邊有漸層
  .item-hover--right {
    position: relative;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 10px #0000001a;

      &:after {
        content: "";
        height: 100%;
        width: 5px;
        display: inline-block;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        @include position(tr, 0, 0);
        @include bg-gradient();
      }
    }
  }

  // 左邊有漸層
  .item-hover--left {
    position: relative;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 10px #0000001a;

      &:after {
        content: "";
        height: 100%;
        width: 5px;
        display: inline-block;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        @include position(tl, 0, 0);
        @include bg-gradient();
      }
    }
  }
}

// * {
//   outline: 1px solid #f00;
// }
