@import "./src/styles/index.scss";

.c-search-tabs {
  display: inline-block;
  @include flex();

  &--is-search-pop {
    .label__box {
      &--active {
        .label__box__text {
          color: $c-white;
        }
      }
    }
  }
}

.label {
  @include flex();
  cursor: pointer;

  & + & {
    margin-left: 20px;
  }

  &__box {
    position: relative;

    &--active {
      .label__box__text {
        color: $c-black;

      }

      .label__box__deco {
        opacity: 1;
      }
    }

    &__text {
      @include font-style($c-assist3, 18, bold, 1px, 27px);
      position: relative;
      z-index: 10;

      &__count {

      @include font-style($c-assist4, 12, normal, 0.6px, 16px);
    }
    }

    &__deco {
      @include position(br, 0px, 4px);
      transition: 0.3s;
      opacity: 0;
    }
  }

  &__total {
    display: none;
  }
}

@media screen and (min-width: $tablet) {
  .label {
    & + & {
      margin-left: 40px;
    }

    &__box {
      &__text {
        @include font-size(20);
      }
    }

    &__total {
      display: inline-block;
      margin-left: 4px;
      @include font-style($c-assist3, 12, normal, 0.6px, 15px);
    }
  }
}
