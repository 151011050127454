@import "./src/styles/index.scss";

.c-search-keyword-dropdown {
  position: relative;

  &--is-search-pop {
    .select-wrap {
      width: 100%;
      border-right: 0px;

      &__text {
        width: 100px;
        color: $c-white;
      }
    }
  }
}

.select-wrap {
  @include flex(space-between);
  width: 125px;
  cursor: pointer;
  position: relative;
  border-right: 1px solid rgba($c-assist3, 0.5);
  padding-right: 15px;

  &__text {
    width: 100px;
    border: 0px;
    outline: 0px;
    background-color: transparent;
    @include font-style($c-assist6, 18, normal, 0.9px, 24px);
    cursor: pointer;
  }
}

.dropdown-icon {
  width: 25px;
  height: 25px;
  position: relative;
  cursor: pointer;

  &__left {
    width: 7px;
    height: 2px;
    background-color: $c-main;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 3px), -50%) rotate(-30deg);
    // left: 3px;
    transition: 0.3s;
  }

  &__right {
    width: 7px;
    height: 2px;
    background-color: $c-main;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 3px), -50%) rotate(30deg);
    // left: -3px;
    transition: 0.3s;
  }

  &--active {
    .dropdown-icon {
      &__left {
        transform: translate(calc(-50% + 3px), -50%) rotate(30deg);
      }

      &__right {
        transform: translate(calc(-50% - 3px), -50%) rotate(-30deg);
      }
    }
  }
}

.mi-select-wrap {
  width: 100%;
  @include position(tl, 100%, 0);
  z-index: 100;
}

.mi-select-list {
  width: 100%;
  background-color: $c-white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px #00000029;
  width: 100%;

  &__list {
    width: 100%;
  }
}
