@import "./src/styles/index.scss";

.c-add-playlists {
  & + & {
    margin-left: 12px;
  }
}
.add-icon {
  width: 15px;
}

.add-text {
  margin-left: 9px;

}
