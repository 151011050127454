@import "./src/styles/index.scss";

.c-mobile-music-info {
  width: 100%;
  height: calc(100% - 60px);
  background-color: rgba($c-white, 0.2);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  transition: 0.3s;
  position: fixed;
  top: calc(100% - 60px);
  @include padding(0 20px 20px);

  &--active {
    background-color: rgba($c-white, 1);
    top: 60px;

    .c-mobile-music-info__content {
      &::after {
        background-color: $c-assist17;
      }
    }

    .tabs {
      &__box {
        &__label {
          color: $c-assist3;

          &--active {
            color: $c-black;
          }
        }
      }
    }
  }

  &__content {
    height: 100%;
    width: 100%;
    position: relative;
    @include flex(flex-start, flex-start, column);

    &::after {
      content: '';
      width: 30px;
      height: 4px;
      border-radius: 2px;
      background-color: $c-white;
      @include position(tl, 5px, 50%);
      transform: translateX(-50%);
    }
  }
}

.tabs {
  flex: none;
  height: 60px;

  width: 100%;
  @include padding(22px 0 16px);
  @include flex();

  position: relative;

  &::after {
    content: '';
    height: 1px;
    width: 100%;
    display: inline-block;
    background-color: $c-assist7;
    @include position(tl, calc(100% + 1px), 0);
  }

  &__box {
    flex: 1;
    text-align: center;

    &__label {
      @include font-style($c-white, 14, 0, 0, 22px);
    }
  }
}

.curr-tab-bar {
  @include position(tl, 100%, 0);
  background-color: $c-main;
  width: 33%;
  height: 3px;
  display: inline-block;
  transition: 0.4s;
  z-index: 10;
}

.content-wrap {
  flex: 1;
  margin: 20px 0 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  @include flex(flex-start, flex-start, column);

  &__content {
    flex: 1;
    height: auto;
    overflow: auto;
    width: 100%;
  }

  &__close {
    flex: none;
    height: auto;
    width: 100%;
    @include flex(center);
    @include padding(20px 0 0 0);
  }
}
