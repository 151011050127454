@import "./src/styles/index.scss";

@import '~@/styles/share/animate.scss';

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  color: $c-white;
  z-index: 1000;
}

.ly-full-header-layout {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  // display: flex;
  // flex-direction: column;
}

.ly-fh {
  &__header {
    width: 100%;
    // height: 52px;
    // position: fixed;
    // z-index: 100;
    // top: 0;
    // left: 0;
  }

  &__ab-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    // @include padding(60px 0 0);

    // &__aside {
    //   flex: none;
    //   width: 200px;
    //   height: 100%;
    //   overflow-y: auto;
    // }

    &__body {
      flex: 1;
      width: 100%;
      height: 100%;
      // overflow-y: auto;
    }
  }
}
