@import "./src/styles/index.scss";

@import '~@/styles/share/functions.scss';

.c-play-pause {
  display: inline-block;
  color: black;
}

.player-btns {
  cursor: pointer;

  &--music-player {
    width: 30px;
  }
}
