// slide-fade
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}



// slide-fade-bottom
.slide-fade-bottom-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-bottom-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-bottom-enter-from,
.slide-fade-bottom-leave-to {
  transform: translateY(20px);
  opacity: 0;
}



.fade-enter-active,
.fade-leave-active {
  // transition-duration: 0.2s;
  // transition-property: opacity;
  // transition-timing-function: ease;
  transition: all 0.3s ease-out;
}

.fade-enter,
.fade-leave-active {
  // opacity: 0;

  transform: translateX(20px);
  opacity: 0;
}