@import "./src/styles/index.scss";

.c-activeity-share-record {
  @include padding(0 24px);
}

.activity-wrap {
  height: 400px;
  overflow-y: auto;
  margin-top: 17px;
}

.activity-list {
  &__item {
    &+& {
      margin-top: 24px;
    }

    &__box {
      &__title {
        @include font-style($c-assist4, 13, normal, 0.7px, 20px);
      }
    }
  }
}

.activity-detail-list {
  margin-top: 8px;

  &__item {
    @include padding(22px 20px);
    background-color: $c-assist1;

    &+& {
      margin-top: 12px;
    }

    &__title {
      @include font-style($c-black, 14, normal, 0.8px, 22px);
    }

    &__time {
      @include font-style($c-assist4, 12, normal, 0.6px, 18px);

      margin-top: 8px;
    }
  }
}

.share-wrap {
  margin-top: 17px;
}

.share-input {
  @include flex();

  &__to {
    @include font-style($c-black, 14, normal, 0.8px, 22px);
  }

  &__box {
    flex: 1;
    @include flex();
    @include padding(14px 25px);
    margin-left: 17px;
    border: 1px solid $c-assist3;
    height: 40px;
    border-radius: 20px;

    &__input {
      flex: 1;
      border: 0 transparent;
      outline: 0 transparent;
      background-color: transparent;
    }
  }
}

.add-btn {
  flex: none;
  width: auto;
  @include flex();
  border: 0 transparent;
  outline: 0 transparent;
  cursor: pointer;
  background-color: transparent;

  &__text {
    margin-left: 10px;
    @include font-style($c-main, 14, normal);
  }
}

.share-list-wrap {
  margin-top: 24px;
}

.total-share-count {
  @include font-style($c-assist4, 14, normal, 0.7px, 20px);
}

.share-list {
  margin-top: 8px;

  height: 400px;
  overflow-y: auto;

  &__item {
    height: 90px;
    @include flex();
    @include padding(0 20px);
    background-color: $c-assist1;

    &+& {
      margin-top: 12px;
    }

    &__box {
      width: 100%;
      @include flex(space-between);

      &__left {
        @include flex();
      }

      &__right {
        @include flex();
      }
    }
  }
}

.left {
  @include flex();

  &__img {
    @include circle(45px);
    overflow: hidden;
  }

  &__info {
    margin-left: 20px;

    &__title {
      @include font-style($c-black, 14, normal, 0.8px, 22px);
    }

    &__time {
      @include font-style($c-assist4, 12, normal, 0.6px, 28px);
    }
  }
}

.right {
  &__resend {
    height: 26px;
    @include flex(center);
    @include font-style($c-white, 14, normal, 0.7px, 20px);
    background-color: $c-assist15;
    border-radius: 13px;
    @include padding(0 10px);
  }

  &__delete {
    margin-left: 20px;
  }
}
