@import "./src/styles/index.scss";

.row {
  &__item {}

  &+& {
    margin-top: 20px;
  }
}

.queue-info-list {
  @include flex();

  &__item {
    width: 100%;

    &--version-info {
      flex: 1;
    }

    &--length {
      @include flex(center);
      flex: none;
      width: 45px;
    }

    &--more {
      @include flex(center);
      flex: none;
      width: 45px;
    }
  }
}
