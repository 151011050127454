@import "./src/styles/index.scss";

.pop-title {
  width: 100%;

  &__text {
    @include padding(0 48px 0 24px);
    @include font-style($c-black, 18, bold, 0.9px, 26px);
  }

  &__deco-line {
    @include padding(16px 0 0 0);
    border-bottom: 1px solid $c-assist7;
  }
}

.pop-content {

  &--content-padding {
    @include padding(20px 24px);

  }
}
