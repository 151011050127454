@import "./src/styles/index.scss";

.c-leave-self-checking-confirm {
  @include flex(center, center, column);
}

.text {
  @include font-style($c-main, 14, 700);
}

.btn-wrap {
  margin-top: 30px;
}
